import React, { useState } from 'react';
import { FaCog } from 'react-icons/fa'; // Importing FontAwesome cog icon for settings

const Navbar = ({ onClear, onRandomize }) => {
  // State to toggle the dropdown menu
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle the dropdown menu visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Handle Clear action
  const handleClear = () => {
    onClear();
    setIsDropdownOpen(false); // Close dropdown
  };

  // Handle Randomize action
  const handleRandomize = () => {
    onRandomize();
    setIsDropdownOpen(false); // Close dropdown
  };

  // Enhanced dropdown menu and button styles
  const dropdownStyles = {
    position: 'absolute',
    right: '20px',
    background: '#fff',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '4px',
    overflow: 'hidden',
    marginTop: '10px',
    zIndex: 1
  };

  const buttonStyles = {
    display: 'block',
    width: '100%',
    padding: '10px 20px',
    textAlign: 'left',
    border: 'none',
    backgroundColor: '#f9f9f9',
    cursor: 'pointer',
    outline: 'none',
  };

  return (
    <nav style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#f0f0f0', padding: '10px 20px' }}>
      <h2>Skills Bingo</h2>
      <div style={{ position: 'relative' }}>
        {/* Gear icon to toggle dropdown */}
        <FaCog onClick={toggleDropdown} style={{ cursor: 'pointer', marginRight: '20px' }} />
        
        {/* Dropdown Menu */}
        {isDropdownOpen && (
          <div style={dropdownStyles}>
            <button onClick={handleClear} style={buttonStyles}>Clear</button>
            <button onClick={handleRandomize} style={buttonStyles}>Randomize</button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;