import React, { useState, useEffect } from 'react';
import BingoGame from './components/BingoGame';
import Navbar from './components/Navbar';
import './App.css';

const App = () => {
  const [key, setKey] = useState(0); // Used to force re-render the BingoGame component

  const handleClear = () => {
    localStorage.removeItem('selectedTiles');
    // Re-render the BingoGame component by changing its key
    setKey(prevKey => prevKey + 1);
  };

  const handleRandomize = () => {
    localStorage.removeItem('bingoSeed'); // Remove the saved seed
    localStorage.removeItem('selectedTiles'); // Clear selected tiles
    setKey(prevKey => prevKey + 1); // Force re-render to apply changes
  };

  return (
    <div>
      <Navbar onClear={handleClear} onRandomize={handleRandomize} />
      <BingoGame key={key} /> {/* Pass key to BingoGame to force re-render */}
    </div>
  );
};

export default App;