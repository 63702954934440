import React, { useState, useEffect } from 'react';

function seededShuffle(array, seed) {
  let currentIndex = array.length, temporaryValue, randomIndex;

  function seededRandom() {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  }

  while (0 !== currentIndex) {
    randomIndex = Math.floor(seededRandom() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const BingoGame = () => {
  const [tiles, setTiles] = useState([]);
  const [selectedTiles, setSelectedTiles] = useState([]);
  const [gridSize, setGridSize] = useState({ rows: 0, cols: 0 });

  useEffect(() => {
    // Check localStorage for seed and selectedTiles
    const savedSeed = parseInt(localStorage.getItem('bingoSeed'), 10);
    const savedSelectedTiles = JSON.parse(localStorage.getItem('selectedTiles')) || [];

    const seed = !isNaN(savedSeed) ? savedSeed : Math.floor(Math.random() * 10000);

    fetch('/bingoTiles.json')
      .then((response) => response.json())
      .then((data) => {
        let idealWidth = Math.ceil(Math.sqrt(data.length));
        let idealHeight = Math.ceil(data.length / idealWidth);
        setGridSize({ rows: idealHeight, cols: idealWidth });

        const totalTiles = idealWidth * idealHeight;
        const blanksToAdd = totalTiles - data.length;
        for (let i = 0; i < blanksToAdd; i++) {
          data.push({ id: `blank-${i}`, value: '', isBlank: true });
        }

        const shuffledTiles = seededShuffle([...data], seed);
        setTiles(shuffledTiles);
        setSelectedTiles(savedSelectedTiles);

        // Save the seed to localStorage
        localStorage.setItem('bingoSeed', seed.toString());
      })
      .catch((error) => console.error("Could not load bingo tiles:", error));
  }, []);

  const toggleTileSelection = (id) => {
    setSelectedTiles(prev => {
      const newSelectedTiles = prev.includes(id) ? prev.filter(tileId => tileId !== id) : [...prev, id];
      
      // Save the new state to localStorage
      localStorage.setItem('selectedTiles', JSON.stringify(newSelectedTiles));

      return newSelectedTiles;
    });
  };

  return (
    <div className="bingo-container">
      <div style={{ display: 'grid', gridTemplateColumns: `repeat(${gridSize.cols}, 1fr)`, gap: '10px' }}>
        {tiles.map((tile) => (
          <button
            className={`bingo-button ${selectedTiles.includes(tile.id) ? 'selected' : ''}`}
            key={tile.id}
            onClick={() => !tile.isBlank && toggleTileSelection(tile.id)}
            style={{ visibility: tile.isBlank ? 'hidden' : 'visible' }}
          >
            {tile.value}
          </button>
        ))}
      </div>
    </div>
  );
};

export default BingoGame;